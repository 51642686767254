@import 'src/styles/media';
@import 'src/styles/variables';

.header {
  padding: 11px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include sm {
    padding: 34px 16px;
  }

  .logo {
    width: 110px;
    height: auto;

    @include sm {
      width: 170px;
    }
  }

  .topRow {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lovePhrase {
    display: flex;
    align-items: center;
    margin-top: 12px;

    font-weight: $mediumFontWeight;
    font-size: 12px;
    text-transform: uppercase;
    color: $black60;
  }

  .loveIcon {
    margin: 0 4px;
  }

  .plus {
    margin-left: 48px;
    margin-right: 48px;
  }
}
