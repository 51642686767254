@import 'src/styles/mixins';

.container {
  @include stepsContainer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  @include h1;
  text-align: center;
  margin-bottom: 8px;

  @include sm {
    margin-bottom: 16px;
  }
}

.subtitle {
  @include subtitle;
  text-align: center;
  margin-bottom: 24px;
}
