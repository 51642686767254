@import 'src/styles/variables';
@import 'src/styles/mixins';

.arrow {
  margin: -8px auto -2px;
}

.arrowDouble {
  margin: -12px auto -8px;

  @include sm {
    transform: scaleX(2.4);

    path {
      stroke-width: 10px;
    }

    path:last-child {
      transform: scaleY(1.22) scaleX(0.7) translateX(47px);
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.card {
  background-color: $blueLight;
}

.personalLoanText::before {
  @include sm {
    content: 'Personal ';
  }
}

.creditCardsText::before {
  @include sm {
    content: 'Credit ';
  }
}

.loan {
  background-color: $yellowLight;
}

.consolidate {
  background-color: $greenLight;
}

.consolidateArrow path {
  fill: linear-gradient(0deg, $greenLight, $irisLight);
}

.term {
  background-color: $irisLight;
}

.container {
  @include stepsContainer;
  @include sm() {
    margin-top: 20px;
  }
}
